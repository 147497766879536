import abi from '../contract/abi.json';
import { Web3Instance } from './useWeb3';

export function ContractInstance() {
  const contractAddress = "0x15241111C8f1aDEE6d1cCbbC97AEf1453a8B6C14";

  const web3 = Web3Instance();
  let contract = new web3.eth.Contract(abi, contractAddress);
  return contract;
}
