import styled from 'styled-components'

export default function AboutPage() {
  return (
    <Container>
      <Title>About</Title>
      <Text>MIEXS is setting the gold standard for the exchange of the future. Easy to use for the beginner and powerful enough for the most experienced trader. MIEXS does everything our competitors do, we just do it bigger, faster and better. We formed based on a diverse group of thinkers and doers that are dedicated to making cryptocurrency available and accessible to our value users, investors and clients to enjoy the trading and other crypto services here. We support multiple languages through various channels, mainly English and offer a wide range of derivatives; whether you are day trading, investing or hedging you will find the best tools to compliment your trading strategy. Earn money by simply moving your trading account to MIEXS and purchasing MIX coin.
.</Text>

    </Container>
  )
}

const Container = styled.div`
  max-width: 450px;
  min-height: calc(100vh - 146px);  
  margin: 0 auto;
  padding: 24px;
`
const Title = styled.h1`
  color: #f1f1f2;
  font-weight: 600;
`
const Text = styled.p`
  color: #84879c;
  font-size: 16px;
`